import css from './Hero.module.css'
import HeroImg from '../../assets/hero.png'
import {FaShoppingBag} from 'react-icons/fa'
import {AiOutlineArrowRight} from 'react-icons/ai'
import {motion} from 'framer-motion'
const Hero = () => {
  const transition = {duration:2, type:String}
  return (
    <div style={{marginTop:"3%"}}>
    <div className={css.container}>
    <div className={css.h_sides}>
    <span className={css.text1}>skin protection cream</span>
    <div className={css.text2}>
      <span>Trendy Collection</span>
      <span>
        Seedily say has suitable disposal and boy. Exercise joy man children
        rejoiced.
        </span>
    </div>
  </div>
  <div className={css.wrapper}>
    <motion.div 
    initial={{bottom:"2rem"}}
    whileInView={{bottom:"0rem"}}
    className={css.blueCircle} transition={transition} ></motion.div>

    <motion.img
    transition={transition}
    initial={{bottom:"-2rem"}}
    whileInView={{bottom:"0rem"}}
    src={HeroImg} alt="" width={600}/>
    <motion.div 
    transition={transition}
    initial={{right:"4%"}}
    whileInView={{right:"2%"}}
    className={css.cart2}>
      <FaShoppingBag />
      <div className={css.signup}>
        <span>Best Signup Offers</span>
        <div>
          <AiOutlineArrowRight />
        </div>
      </div>
    </motion.div>
  </div>
  <div className={css.h_sides}>
    <div className={css.traffic}>
      <span>1.5m</span>
      <span>Monthly traffic</span>
    </div>
    <div className={css.customers}>
      <span>100k</span>
      <span>Happy Customers</span>
    </div>
  </div>
</div>
</div>
  )
}

export default Hero