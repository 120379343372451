import css from './Products.module.css'
import Plane from '../../assets/plane.png'
import { useState } from 'react'
import {ProductsData} from '../../data/products'
import {useAutoAnimate} from '@formkit/auto-animate/react'

const Products = () => {
  const [products  , setProducts]  = useState(ProductsData)
  const [parent] = useAutoAnimate()
  const filter = (type)=>{
    setProducts(ProductsData.filter((product)=>product.type === type))
  }
  return (
    <div className={css.container}> 
    <img src={Plane} alt="" />
      <h1>Our Featured Products</h1>

      <div className={css.products}>
        <ul className={css.menu}>
          <li onClick={()=>setProducts(ProductsData)}>All</li>
          <li onClick={()=>filter("skin care")}>Skin Care</li>
          <li onClick={()=>filter("conditioner")}>Conditioner</li>
          <li onClick={()=>filter("foundation")}>Foundations</li>
        </ul>
        <div className={css.list} ref={parent}>
          {products.map((product , i)=>(
            <div className={css.product} key={i}>
              <div className='left-s'>
              <div className='name'>
              <span>{product.name}</span>
              <span>{product.detail}</span>
              </div>
              <span>${product.price}</span>
              <div>Shop Now</div>
              </div>
              <img src={product.img} alt="Product" className='img-p' />
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Products